div#backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 400;
}

nav {
  background-color: $white;
  position: sticky;
  top: 0;
  z-index: 10;
  #logoLink svg {
    max-width: 100%;
  }
}

@keyframes swing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

#nav-wrapper {
  @include media-breakpoint-up(lg) {
    box-shadow: none !important;
  }

  #navCart {
    .badge.animate {
      animation: swing 0.3s ease-in-out 1
        alternate;
    }
  }
  &.sticky-top {
    z-index: 320;
  }
}

nav#sideDrawer {
  height: 100%;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  max-width: 400px;
  z-index: 500;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  overflow: scroll;
  visibility: hidden;
  button.btn {
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &.open {
    visibility: visible;
    transform: translateX(0);
  }
  .btn {
    color: #fff;
  }
  .navLinks {
    background-color: $gray200;
    button,
    a {
      color: $black;
    }
  }
  ul {
    display: block !important;
    min-height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    cursor: pointer;
    margin: 0;
    a {
      padding: 20px;
      color: #fff;
      display: block;
      text-decoration: none;
      text-transform: capitalize;
      &.active {
        border-left: solid 4px $primary;
        background-color: #ececec;
        font-weight: bold;
        color: $primary;
        &:hover {
          color: #003300;
        }
      }
    }
  }
}

button#hamburgerButton {
  background: none;
  border: none;
  padding: 0;
  @include media-breakpoint-up(lg) {
    display: none;
  }
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
    span.line {
      width: 30px;
      height: 2px;
      background: $gray800;
    }
  }
}
#productNavLinks {
  background-color: $primary;
  nav#headerNav {
    background-color: $primary;
    ul.navLinks {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      z-index: 100;
      list-style-type: none;
      li.dropdown,
      a.nav-link {
        text-transform: uppercase;
        position: relative;
        font-size: 14px;
        color: $gray200;
        transition: all 0.2s ease-in-out;
        padding-top: 12px;
        padding-bottom: 12px;
        &:hover {
          background-color: #c60b13;
        }
        &.active {
          background-color: #c60b13;
          font-weight: 700;
          // color: $black;
        }
      }

      li {
        flex-grow: 1;
        &:last-of-type {
          padding-right: 0;
        }
        a {
          text-decoration: none;
          text-transform: capitalize;
          text-align: center;
        }
      }

      li.dropdown {
        position: relative;
        > ul {
          list-style-type: none;
          background-color: #fff;
          display: none;
          position: absolute;
          top: 25px;
          left: -10px;
          margin-left: 0;
          margin-right: 0;
          border-radius: 5px;
          z-index: -1;
        }
        &:hover > ul {
          display: block;
        }
      }
      li#dealNav {
        background-color: $black;
        a {
          font-weight: 600;
          color: $white;
          &:hover,
          &.active {
            background-color: $black;
            color: $white;
          }
          position: relative;
          span {
            color: $primary;
          }
          &.active {
            font-weight: 700;
          }
        }
      }
    }
  }
}
