#categoryPage {
  h1 {
    font-size: 24px;
  }
  ol.breadcrumb {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    background-color: inherit;
  }
  #categoryBanner {
    position: relative;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    img {
      width: 100%;
      max-height: 350px;
      object-fit: cover;
      object-position: center center;
    }
  }

  #facet-filters {
    input[type="checkbox"],
    .form-check,
    .form-check-label {
      cursor: pointer;
    }

    input[type="checkbox"].form-check-input,
    input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: white;
      border: 2px solid $gray-400;
      padding: 7px;
      margin-right: 15px;
      position: relative;

      &:focus {
        outline: none;
      }

      &:checked:active {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05),
          inset 0px 1px 3px rgba(0, 0, 0, 0.1);
      }

      &:checked {
        background-color: $red;
        border: 2px solid $red;
      }

      &:checked:after {
        content: url("/icons/check-solid.svg");
        width: 13px;
        position: absolute;
        top: -4px;
        left: 1px;
        color: black;
        display: block;
      }
    }
  }
}
