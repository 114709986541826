#dashboardProducts {
    thead {
        > tr > th {
            white-space: nowrap;
        }
        .icon-up:before {
            content: "\25B2";
            font-size: 11px;
        }

        .icon-down:before {
            content: "\25BC";
            font-size: 11px;
        }
    }

}