#homePage {

  section.productSection .productHeader {
    position: relative;
    h2 {
      z-index: 1;
    }
    &:before {
      content: "";
      width: 100%;
      height: 2px;
      background: $primary;
      position: absolute;
      bottom: 50%;
      left: 0;
      right: 0;
    }
  }
}
