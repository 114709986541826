#checkout-progress {
  .bg-gradiented {
    background: linear-gradient(
      90deg,
      rgba(14, 30, 37, 0),
      $primary 20%,
      $primary 80%,
      rgba(14, 30, 37, 0)
    );
  }
}
