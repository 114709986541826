#footer {
  h2,
  h3 {
    font-size: 16px;
  }
  a,
  p,
  address,
  span {
    font-size: 12px;
    font-weight: 300;
  }
  #footerBrands svg {
    display: inline-block;
    overflow: visible;
    width: 1.5em;
    height: 1.5em;
    font-size: inherit;
    vertical-align: -0.125em;
    fill: currentColor;
  }
  #footer-top {
    a {
      color: #fff;
    }
    color: #fff;
    background-color: $black;
  }
}
