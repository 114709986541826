.addToBasketButton {
  max-width: 300px;
  .btn.btn-success {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex-grow: 1;
    margin-left: 2px;
  }
  .basketQuantity {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: $gray200;
    border-radius: 0.25rem 0 0 0.25rem;
    align-self: stretch;
    flex-basis: 55px;
    span.qty {
      position: absolute;
      left: 0;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      background-color: #fff;
      padding: 4px 10px;
      display: inline-block;
      color: $black;
      border: solid 1px $gray200;
      border-left: none;
      line-height: 1.8;
      font-size: 14px;
    }
    .quantityButtons {
      button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: none;
        line-height: 1;
        background-color: $gray200;
        &:hover {
          background-color: $gray400;
        }
        &:focus {
          outline: none;
        }
        &:first-of-type {
          border-bottom: solid 1px $white;
        }
        &:last-of-type {
          border-top: solid 1px $white;
        }
      }
    }
  }

}