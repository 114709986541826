#search {
  z-index: 100;
  .input-group {
    transition: border 0.3s ease-in-out;
  }
  #text-search {
    input:focus {
      outline: none;
      box-shadow: none;
      border-color: $gray400;
    }
  }
  #downshift-0-menu {
    display: block !important;
    max-height: 80vh;
    left: 0;
    @include media-breakpoint-up(lg) {
      min-width: 600px;
      max-height: 90vh;
    }
    overflow-y: scroll;
    @include media-breakpoint-down(lg) {
      max-height: 75vh;
      width: 100%;
    }

  }
  .highlighted {
    border-left: solid 8px $primary;
  }
  #searchSpinner {
    right: 2px;
    background: #fff;
    top: 6px;
  }
}
