#checkoutPage {
  #order-overview {
    img {
      width: 120px;
    }
  }

  .list-group-item
    + .list-group-item.border-success {
    border-top-width: 1px;
  }
}
