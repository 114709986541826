#pdp {
  ol.breadcrumb {
    margin-bottom: 0;
    background-color: #fff;
  }
  .imgContainer {
    cursor: pointer;
    position: relative;
    > .badge {
      position: absolute;
      left: 0;
      top: 20px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px;
    }
    > div {
      // next-image component
      display: block !important;
      margin: 0 auto !important;
    }

    img {
      display: block;
      width: 300px;
      margin: 0 auto;
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
  }
  .purchase-info {
    span {
      font-size: 15px;
    }
  }
  #topInfo > span::before {
    content: "|";
    margin: 0 10px;
  }
  #topInfo > span:first-child:before {
    display: none;
  }
  #listingPrice {
    font-size: 20px;
    text-decoration: line-through;
  }
  #sellingPrice {
    font-size: 32px;
    font-weight: bold;
    color: $primary;
  }
}
