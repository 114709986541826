#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: $primary;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $primary, 0 0 5px $primary;
  opacity: 1;
  -webkit-transform: rotate(3deg)
    translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}
