.productTile {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  font-size: 14px;
  a > div {
    margin: 0 auto; // next-image component
  }
  h3 {
    font-size: 16px;
  }
  .badge {
    position: absolute;
    letter-spacing: 1px;
    left: 0;
    top: 20px;
    font-size: 12px;
    padding: 4px;
    font-weight: 400;
    border-radius: 0px 5px 5px 0px;
  }
  .card-body {
    .sellingPrice {
      font-size: 18px;
    }
  }
  .purchase-info {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  @include media-breakpoint-down(sm) {
      font-size: 12px;
    .badge {
      top: 5px;
      letter-spacing: 0;
    }
    h3 {
      font-size: 14px;
    }
    .addToBasketButton .basketQuantity {
      display: none;
    }
    .purchase-info {
      svg {
        width: 14px;
        height: 14px;
      }
      font-size: 12px;
    }
  }
}
