// $border-radius: 0px;
// $border-radius-lg: 0px;
// $border-radius-sm: 0px;
$primary: #EE1C25;
$secondary: #333;
$danger: #CE796B;
$success: #38a16a;
$accent: #ff8d00;
$white: #fff;
$black: #000;

$gray100: #f8f9fa;
$gray200: #e9ecef;
$gray300: #dee2e6;
$gray400: #ced4da;
$gray500: #adb5bd;
$gray600: #6c757d;
$gray700: #495057;
$gray800: #343a40;
$gray900: #212529;

$green100: #F0FFF4;
$green200: #C6F6D5;
$green300: #9AE6B4;
$green400: #68D391;
$green500: #48BB78;
$green600: #38A169;
$green700: #2F855A;
$green800: #276749;
$green900: #22543D;

$red100: #FFF5F5;
$red200: #FED7D7;
$red300: #FEB2B2;
$red400: #FC8181;
$red500: #F56565;
$red600: #E53E3E;
$red700: #C53030;
$red800: #9B2C2C;
$red900: #742A2A;

$font-family-sans-serif: "Proza Libre";

$font-size-base: 1rem !default;
$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.4 !default;
$h4-font-size: $font-size-base * 1.3 !default;
$h5-font-size: $font-size-base * 1.2 !default;
$h6-font-size: $font-size-base !default;

// Used to override bootstrap default arrow down icons with dropdowns
$enable-caret: true;

// Your variable overrides
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "success": $success,
  "accent": $accent,
  "white": $white,
);
